<template>
  <div class="reset-password">
    <ForgotPasswordModal
      @got-email="gotEmailMessage"
      @resent-password="resentPassword"
      :email="formData.email"
      @show-modal="closeModal($event)"
      :showing-modal="showModal"/>
    <Content>
      <div class="reset-password-content">
        <div class="text-reset-password">
          <span class="text-reset-password">ENTER EMAIL</span>
        </div>
        <form class="form">
          <BaseInput
            class="form-group"
            id="login_email"
            :placeholder="configEnv.onboarding.emailPlaceHolder"
            v-model="formData.email"
            :hasError="$v.formData.email.$error"
            :show-done="($v.formData.email.required && $v.formData.email.mustBeCool)"
            ref="baseInput"
          >
          </BaseInput>
          <div class="form-group button-group form-group_submit">
            <button
              class="button cancel-button"
              @click.prevent="login"
            >
              Cancel
            </button>
            <button
              class="button button_disabled button-reset-password"
              @click.prevent="resetPassword"
              id="log_in"
              :disabled="$v.formData.email.$invalid"
            >
              RESET
            </button>
          </div>
        </form>
      </div>
    </Content>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import configEnv from '@configEnv';
import ForgotPasswordModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ForgotPasswordModal/ForgotPasswordModal.vue';
import helpFunction from '@helpers/helpFunction';
import BaseInput from '@innerworks_ds/innerworks-storybook-main/src/stories/CustomInputs/BaseInput/BaseInput.vue';

const { required } = require('vuelidate/lib/validators');

export default {
  components: { ForgotPasswordModal, BaseInput },
  mixins: [validationMixin],
  validations: {
    formData: {
      email: {
        required,
        mustBeCool: helpFunction.mustBeCool,
      },
    },
  },
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
  },
  data: () => ({
    configEnv,
    showModal: false,
    formData: {
      email: null,
    },
    loading: false,
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    gotEmailMessage(value) {
      this.$router.push(value);
    },
    async resentPassword() {
      await this.$store.dispatch('auth/resetPasswordRequest', { email: this.formData.email });
      this.loadingForgotPassword = false;
    },
    closeModal(value) {
      this.showModal = value;
    },
    login() {
      this.$router.push({
        name: 'main',
        params: {
          login: 'login',
        },
      }).catch(() => {});
    },
    async resetPassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          await this.$store.dispatch('auth/resetPasswordRequest', { email: this.formData.email });
          this.loading = false;
          this.showModal = true;
        } catch (error) {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  height: 100%;
}
.container {
  height: 100%;
}
.reset-password-content {
  text-align: center;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 100px;
}

.text-content {
  font-family: $newDefaultFont;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #222222;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
}

.title-text {
  font-family: $newDefaultFont;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  color: #320959;
}

.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  font-size: 20px;
  font-family: $newDefaultFont;
  margin-top: 45px;
}

.right-button {
  padding: 12px 61px;
  border: 1px solid #FF5151;
  box-sizing: border-box;
  border-radius: 5px;
  background: #FF5151;
}

.left-button {
  padding: 12px 14px;
  background: #7811C9;
  border: 1px solid #7811C9;
  box-sizing: border-box;
  border-radius: 5px;
}

.text-reset-password {
  font-family: $newDefaultFont;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 45px;
  color: rgba(50, 9, 89, 0.8);
}
.button-reset-password {
  background-color: #7811C9;
  padding: 12px 61px 12px 61px;
  border-radius: 5px;
  width: 100%;
  bottom: 0;
  height: 80px;
  position: fixed;
  max-width: 480px;
}
.button-reset-password:disabled {
  background-color: rgba(141, 69, 197, 0.93);
}
.cancel-button {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #7811C9;
  position: fixed;
  bottom: 110px;
  background: transparent;
}
</style>
